(function($, window, document) {
    "use strict";
    $(function() {
        //##Variables
        var $body = $('body'),
            $window = $(window),
            $doc = $(document),
            $mobileNav = $('.mobile_nav'),
            $hamburger = $('.hamburger_holder'),
            defaultEasing = [0.4, 0.0, 0.2, 1];
        //End Variables

        $('.auto_slider').owlCarousel({
            items: 2,
            nav: false,
            dots: false,
            stagePadding: 15,
            loop: true,
            responsive: {
              0: {
                stagePadding: 15
              },
              414: {
                stagePadding: 15
              }
            }
        });


        $hamburger.click(function() {
            $body.toggleClass('navToggled');
        });



        // Tabs JS
        $('.cat_multiple .cat-item a').attr('href', 'javascript:void(0);');

        var $multipleCats = $('.cat_multiple');

        $multipleCats.each(function() {   
        var $thisParent = $(this),
            $cats = $thisParent.find('.cat-item'),
            $contentParent = $thisParent.find('.multiple_cats_content');
            
            $cats.each(function(index, element) {
                
                var $currentClass = $('.multi_cat_'+index);
                $(element).addClass('multi_cat_'+index);

                $(element).click(function(event) {
                    var $identicalClass = $contentParent.find($currentClass);
                    $identicalClass.siblings().removeClass('active');
                    $identicalClass.addClass('active');
                });
            });
        });


        console.log("%c Developed by: Kutia Digital Agency http://kutia.net", "padding:9px 5px; background:#3c4952; line-height:25px;color: #59bf9d");
    });
}(window.jQuery, window, document));
